@import '../../variables.scss';

.header {
    background-color: #B84719;
    height: 130px;
    
    .logo {
        width: $base-width;
        margin: 0 auto;
        text-align: left;
        height: 85px;
        padding-top: 20px;

        h1 {
            float:left;
            margin: 0px;
            font-size: 26px;
        }

        .input-group {
            width: 200px;
            float:right;

            input, button {
                height: 30px;
                line-height: 0.8;
                font-size: 14px;
            }
        }
    }
}

nav {
    text-align: right;
    width: $base-width;
    margin: 0 auto;
}

.menu {
    margin: 0;
    display: inline-block;

    li {
        padding: 6px 0px 6px;
        float: left;
        margin: 0;
        list-style-type: none;
        font-size: 18px;

        a.active {
            border-bottom: 5px solid #A2AF9F;
        }
        a {
            padding: 10px 20px;
            color: #fff;
            text-decoration: none;

            &:hover {
                border-bottom: 5px solid #fff;
            }
        }
    }
}

.smaller-select .ReactVirtualized__Grid {
    max-height: 250px!important;
}