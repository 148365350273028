@import '../../variables.scss';

.content {
    width: 100%;
    max-width: $base-width;
    margin: 25px auto 0;
    display: flex;

    h1 {
        margin-top: 0;
    }
}