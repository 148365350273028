.image {
    width: 100px;
}

.fa-stack[data-count]:after{
    position:absolute;
    right:-5px;
    top:10px;
    content: attr(data-count);
    font-size:7px;
    padding:.6em;
    border-radius:999px;
    line-height:12px;
    color: white;
    background:rgba(255,0,0,.85);
    text-align:center;
    min-width:2em;
    font-weight:bold;
  }

.ReactVirtualized__Table__row {
    overflow: visible!important;
}