body {
  margin: 0;
  font-family: 'Manrope', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  font-size: 14px;
}

* {
  box-sizing: border-box;
}

::selection {
  background: #BDA79E; /* WebKit/Blink Browsers */
}
::-moz-selection {
  background: #BDA79E; /* Gecko Browsers */
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}
